.theme-jp {
	--theme-color-white: var(--color-white);
	--theme-color-neutral-50: var(--color-grey-50);
	--theme-color-neutral-100: var(--color-grey-100);
	--theme-color-neutral-200: var(--color-grey-200);
	--theme-color-neutral-300: var(--color-grey-300);
	--theme-color-neutral-350: var(--color-grey-350);
	--theme-color-neutral-400: var(--color-grey-400);
	--theme-color-neutral-450: var(--color-grey-450);
	--theme-color-black: var(--color-black);

	--theme-color-article-teaser-kicker: var(--theme-color-primary);
	--theme-color-cta: var(--color-jp-cta-green);
	--theme-color-cta-hover: var(--color-jp-cta-green-hover);
	--theme-color-logo-primary: var(--color-black);
	--theme-color-primary: var(--color-jp-racing-green);
	--theme-color-primary-hover: var(--color-jp-racing-green-hover);
	--theme-color-mono: var(--color-jp-media-grey);
	--theme-color-mono-hover: var(--color-black);
	--theme-color-warning: var(--color-jp-warning-red);
	--theme-color-warning-hover: var(--color-jp-warning-red-hover);
    --theme-color-live: var(--color-jp-live-red);
    --theme-color-rating: var(--color-jp-rating-yellow);
    --theme-color-breaking: var(--color-jp-breaking-yellow);
    --theme-color-breaking-separator: var(--color-jp-breaking-yellow-line);
    --theme-color-advertorial: var(--color-advertorial-blue);

	--theme-font-article-body: var(--font-family-guardian-egyptian-text);
	--theme-font-article-heading: var(--font-family-guardian-egyptian-headline);
	--theme-font-article-marker: var(--font-family-caslon-ionic);
	--theme-font-generic-body: var(--font-family-guardian-sans-text);
	--theme-font-generic-label: var(--font-family-guardian-sans-headline);
	--theme-font-sponsored-content: var(--font-family-hind);

	--theme-spacing-button-text-vertical-alignment-correction: 0.0625rem; /* Guardian Sans appears taller than Open Sans and Roboto. */
	--theme-spacing-header-logo-height: 1.375rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0.125rem;
}
