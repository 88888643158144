.theme-lokalavisen {
	--theme-color-white: var(--color-white);
	--theme-color-neutral-50: var(--color-grey-50);
	--theme-color-neutral-100: var(--color-grey-100);
	--theme-color-neutral-200: var(--color-grey-200);
	--theme-color-neutral-300: var(--color-grey-300);
	--theme-color-neutral-350: var(--color-grey-350);
	--theme-color-neutral-400: var(--color-grey-400);
	--theme-color-neutral-450: var(--color-grey-450);
	--theme-color-black: var(--color-black);

	--theme-color-article-teaser-kicker: var(--color-lokal-orange);
	--theme-color-cta: var(--color-lokal-cta-blue);
	--theme-color-cta-hover: var(--color-lokal-cta-blue-hover);
	--theme-color-logo-primary: var(--theme-color-primary);
	--theme-color-logo-secondary: var(--theme-color-article-teaser-kicker);
	--theme-color-primary: var(--color-lokal-blue);
	--theme-color-primary-hover: var(--color-lokal-blue-hover);
	--theme-color-mono: var(--theme-color-neutral-450);
	--theme-color-mono-hover: var(--theme-color-black);
	--theme-color-warning: var(--color-lokal-cta-red);
	--theme-color-warning-hover: var(--color-lokal-cta-red-hover);

	--theme-font-article-body: var(--font-family-nunito);
	--theme-font-article-heading: var(--font-family-roboto);
	--theme-font-article-marker: var(--font-family-roboto);
	--theme-font-generic-body: var(--font-family-roboto);
	--theme-font-generic-label: var(--font-family-roboto);
	--theme-font-sponsored-content: var(--font-family-hind);

	--theme-spacing-button-text-vertical-alignment-correction: 0;
	--theme-spacing-header-logo-height: 1.5rem;
	--theme-spacing-header-logo-vertical-alignment-correction: -0.0625rem;
}

.theme-din-avis {
	@apply theme-lokalavisen;

	--theme-color-article-teaser-kicker: var(--color-lokal-blue);
	--theme-color-cta: var(--color-lokal-cta-blue);
	--theme-color-cta-hover: var(--color-lokal-cta-blue-hover);
	--theme-color-logo-primary: var(--color-din-avis-blue);
	--theme-color-logo-secondary: var(--theme-color-black);
	--theme-color-primary: var(--color-lokal-blue);
	--theme-color-primary-hover: var(--color-lokal-blue-hover);

	--theme-spacing-header-logo-height: 1.875rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0;
}

.theme-favrskov {
	@apply theme-lokalavisen;

	--theme-spacing-header-logo-height: 2rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0;
}

.theme-lokalavisen-norddjurs {
	@apply theme-lokalavisen;

	--theme-spacing-header-logo-height: 2.25rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0.125rem;
}

.theme-randers {
	@apply theme-din-avis;
}

.theme-skanderborg {
	@apply theme-lokalavisen;

	--theme-color-article-teaser-kicker: var(--color-skanderborg-green);
	--theme-color-cta: var(--color-lokal-cta-green);
	--theme-color-cta-hover: var(--color-lokal-cta-green-hover);
	--theme-color-primary: var(--color-skanderborg-green);
	--theme-color-primary-hover: var(--color-skanderborg-green-hover);

	--theme-spacing-header-logo-height: 2.375rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0.0625rem;
}

.theme-syddjurs {
	@apply theme-lokalavisen;

	--theme-spacing-header-logo-height: 2.375rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0.125rem;
}

.theme-aarhus {
	@apply theme-din-avis;
}

.theme-aarhusonsdag {
	@apply theme-lokalavisen;

	--theme-color-article-teaser-kicker: var(--color-aarhus-red);
	--theme-color-cta: var(--color-lokal-cta-red);
	--theme-color-cta-hover: var(--color-lokal-cta-red-hover);
	--theme-color-logo-secondary: var(--theme-color-black);
	--theme-color-primary: var(--color-aarhus-red);
	--theme-color-primary-hover: var(--color-aarhus-red-hover);

	--theme-spacing-header-logo-height: 1.625rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0;
}
