/**
 * All colours are defined in RGB (which is equivalent to hex colours),
 * but the `rgb()` colour function has been omitted to allow Tailwind CSS
 * to enable the opacity modifier:
 * https://tailwindcss.com/docs/customizing-colors#using-css-variables
 */
:root {
	--color-white: 255 255 255; /* #ffffff */
	--color-grey-50: 238 238 238; /* #eeeeee */
	--color-grey-100: 204 204 204; /* #cccccc */
	--color-grey-200: 153 153 153; /* #999999 */
	--color-grey-300: 102 102 102; /* #666666 */
	--color-grey-350: 68 68 68; /* #444444 */
	--color-grey-400: 51 51 51; /* #333333 */
	--color-grey-450: 34 34 34; /* #222222 */
	--color-black: 0 0 0; /* #000000 */

    --color-jp-breaking-yellow-line: 186 131 0; /* #ba8300 */
    --color-jp-advetorial-blue: 0 156 221; /* #009cdd */
	--color-jp-breaking-yellow: 253 191 45; /* #fdbf2d */
	--color-jp-cta-green: 53 182 173; /* #35b6ad */
	--color-jp-cta-green-hover: 36 138 136; /* #248a88 (unofficial) */
	--color-jp-feature-green: 36 138 136; /* #248a88 */
	--color-jp-hot-orange: 214 104 19; /* #d66813 */
    --color-jp-live-red: 212 53 58; /* #D4353A */
    --color-jp-media-grey: 63 70 74; /* #3f464a */
	--color-jp-opinion-beige: 221 214 199; /* #ddd6c7 */
	--color-jp-opinion-background: 238 234 227; /* #eeeae3 */
	--color-jp-racing-green: 0 104 96; /* #006860 */
	--color-jp-racing-green-hover: 0 75 69; /* #004b45 */
	--color-jp-rating-yellow: 238 174 54; /* #eeae36 */
	--color-jp-warning-red: 218 80 84; /* #da5054 */
	--color-jp-warning-red-hover: 186 62 63; /* #ba3e3f */

	--color-finans-analysis-grey: 39 42 50; /* #272a32 */
	--color-finans-analysis-background: 22 24 31; /* #16181f */
	--color-finans-breaking-yellow: 255 225 70; /* #ffe146 */
	--color-finans-breaking-background: 251 211 4; /* #fbd304 */
	--color-finans-cta-red: 255 80 113; /* #ff5071 */
	--color-finans-cta-red-hover: 200 50 82; /* #c83252 (unofficial) */
	--color-finans-cta-green: 20 193 151; /* #14c197 */
	--color-finans-cta-orange: 239 125 0; /* #ef7d00 */
	--color-finans-feature: 29 53 87; /* #1d3557 */
	--color-finans-numbers-green: 0 129 98; /* #008162 */
	--color-finans-numbers-red: 229 0 52; /* #e50034 */
	--color-finans-opinion-beige: 251 236 222; /* #fbecde */
	--color-finans-opinion-background: 224 212 200; /* #e0d4c8 */
	--color-finans-red: 167 10 44; /* #a70a2c */
	--color-finans-red-hover: 167 10 44; /* #a70a2c (unofficial) */
	--color-finans-topic: 0 165 183; /* #00a5b7 */

    --color-advertorial-blue: 0 156 221; /* #009cdd */
    --color-annoncoerbetalt-indhold-background: 197 207 212; /* #c5cfd4 */
	--color-annoncoerbetalt-indhold-grey: 40 57 61; /* #28393d */
	--color-annoncoerbetalt-indhold-grey-hover: 63 90 97; /* #3f5a61 */
	--color-annoncoerbetalt-indhold-links: 22 190 207; /* #16becf */
	--color-din-avis-blue: 2 59 126; /* #023b7e */
	--color-lokal-blue: 0 105 159; /* #00699f */
	--color-lokal-blue-hover: 43 138 184; /* #2b8ab8 */
	--color-lokal-breaking-yellow: 254 213 0; /* #fed500 */
	--color-lokal-cta-blue: 0 105 159; /* #00699f (unofficial) */
	--color-lokal-cta-blue-hover: 43 138 184; /* #2b8ab8 (unofficial) */
	--color-lokal-cta-green: 1 159 145; /* #019f91 (unofficial) */
	--color-lokal-cta-green-hover: 58 188 172; /* #3abcac (unofficial) */
	--color-lokal-cta-orange: 239 125 0; /* #ef7d00 (unofficial) */
	--color-lokal-cta-orange-hover: 239 125 0; /* #ef7d00 (unofficial) */
	--color-lokal-cta-red: 229 0 81; /* #e50051 (unofficial) */
	--color-lokal-cta-red-hover: 255 57 104; /* #ff3968 (unofficial) */
	--color-lokal-orange: 239 125 0; /* #ef7d00 */
	--color-lokal-orange-hover: 239 125 0; /* #ef7d00 (unofficial) */
	--color-skanderborg-green: 1 159 145; /* #019f91 */
	--color-skanderborg-green-hover: 58 188 172; /* #3abcac */
	--color-aarhus-red: 229 0 81; /* #e50051 */
	--color-aarhus-red-hover: 255 57 104; /* #ff3968 */
}
